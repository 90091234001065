import React from "react";
import Layout from "../components/organisms/layout/layout";
import ProjectsTemplate from "../components/templates/projects-template/projectsTemplate";

const Projects = () => {
  return (
    <Layout title="Dicar | Proyectos">
      <ProjectsTemplate />
    </Layout>
  )
}

export default Projects;